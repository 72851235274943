import React, { useContext } from "react";
import PropTypes from "prop-types";
import IncidentDetails from "./incident-details";
// import UnitLayout from "components/unit-layout";
import { LocaleContext } from "../../context/locale-context";
import useDataApi from "../../hooks/useDataApi";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const config = require(`../../../config/i18n`);
// This will be used in next few weeks to make a generalised observation Details for all sites, override it for now
function IncidentWrapper({ id }) {
  const locale = useContext(LocaleContext);
  const { apiAuthHeader, apiUrl } = config;
  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    `${apiUrl}/yemen/${locale}/incidents/${id}`,
    [],
    apiAuthHeader
  );
  if (isLoading) {
    return (
      <div
        css={{
          margin: "10rem",
        }}
      >
        <Skeleton count={30} />
      </div>
    );
  }

  // get observation details and pass on to the details component
  return (
    <>
      {/* <UnitLayout location={{}} isModal={false}> */}
      <IncidentDetails data={data} locale={locale} loading={isLoading} />
      {/* </UnitLayout> */}
    </>
  );
  // return <>Unit Details</>;
}

IncidentWrapper.propTypes = {
  id: PropTypes.object,
  locale: PropTypes.string,
};

export default IncidentWrapper;
