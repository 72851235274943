import React from "react";
import IncidentWrapper from "../components/data/incident-wrapper";

function Incident({ location }) {
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  return <IncidentWrapper id={id} />;
}

export default Incident;
